<template>
    <BaseItemWidget>
        <FormAction :id="id" :copy="copy" />

        <b-form @submit="onSubmit" @reset="onReset">
            <b-form-group id="name-group" label="Name:" label-for="name">
                <b-form-input
                    id="name"
                    v-model="form.name"
                    required
                    placeholder="Enter Name"
                ></b-form-input>
            </b-form-group>

            <b-form-group
                id="description-group"
                label="Description:"
                label-for="description"
            >
                <b-form-textarea
                    id="description"
                    v-model="form.description"
                    placeholder="Enter Description"
                    rows="6"
                ></b-form-textarea>
            </b-form-group>

            <!-- <b-form-group
                id="permissions-group"
                label="Permissions:"
                label-for="permissions"
            >
                <b-form-checkbox-group
                    id="permissions"
                    v-model="form.permissions"
                    :options="permissions"
                    name="permissions"
                    value-field="slug"
                    text-field="name"
                    stacked
                ></b-form-checkbox-group>
            </b-form-group> -->

            <b-form-group id="role-group" label="Permissions:" label-for="permissions">
                <multiselect
                    v-model="form.permissions"
                    :multiple="true"
                    :options="permissions"
                    track-by="slug"
                    label="name"
                ></multiselect>
            </b-form-group>

            <b-form-group id="simcell-group" label="Sim Cell:" label-for="simcells" v-if="exercise._id">
                <multiselect
                    v-model="form.simcell"
                    :options="simcells"
                    track-by="_id"
                    label="name"
                ></multiselect>
            </b-form-group>

            <!-- <b-form-group id="exercise-group" v-if="exercise">
        <b-form-checkbox v-model="form.exercise" :value="exercise._id" unchecked-value="" class="mb-3">
          Add to {{ exercise.name }}
        </b-form-checkbox>
      </b-form-group>

      <div class="row">
        <div class="col">
          <b-button block variant="outline-primary" type="submit">Save</b-button>
        </div>
        <div class="col">
          <b-button block variant="outline-warning" type="reset">Clear</b-button>
        </div>
      </div> -->

            <FormButtonOptions :exercise="exercise" :id="id" :copy="copy" />
        </b-form>
    </BaseItemWidget>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import * as _ from "lodash";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "RoleForm",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
        id: {
            type: String,
            required: false,
            default: null,
        },
        input: {
            type: Object,
            required: false,
            default: Object,
        },
        copy: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: function () {
        return {
            item: null,
            form: {
                name: "",
                description: "",
                permissions: [],
                exercise: false,
                simcell: null,
            },
            permissions: [],
            simcellItems: [],
            simcells: []
        };
    },
    watch: {
        exercise: function (val) {
            if (val._id) {
                this.form.exercise = true;
            } else {
                this.form.exercise = false;
            }
        },
        id: function (val) {
            if (val) {
                this.init();
            } else {
                this.item = {};
                this.form = {};
            }
        },
        input: function (val) {
            this.item = this.input;
            this.form = _.extend({}, this.input);
            
            if(typeof this.form.simcell == "string") {
                this.form.simcell = {_id: this.form.simcell};
            }
        },
    },
    computed: {
        ...mapState("sample", ["sample"]),
    },
    created: function () {
        this.get();

        if (this.id) {
            this.init();
        }

        // initSimcellItems();
    },
    mounted: function () {},
    methods: {
        init() {
            const that = this;
            that.$http.get(`/role/${that.id}`).then((response) => {
                that.item = response.data;

                const form = _.extend({}, that.item);
                form._id = that.copy ? undefined : form._id;
                // form.simcell = {_id: form.simcell};
                if(typeof form.simcell == "string") {
                    const simcell = that.simcells.find(item => item._id == form.simcell);
                    form.simcell = simcell;
                }
                that.form = form;

                if(that.form.permissions.length && typeof that.form.permissions[0] == "string") {
                    that.form.permissions = [];
                }
            });
        },
        async initSimcellItems() {
            const that = this;
            that.simcellItems = await that.$http.get('/simcell', {
                params: {
                    exercise: that.exercise._id
                }
            });

            that.socketSubscribe(`${that.exercise._id}-sim-cell-list`, that.simcellItems);
        },
        get: async function () {
            const that = this;
            const header = {params: {}};

            if(that.exercise._id) {
                header.params.exercise = that.exercise._id;
            }

            this.permissions = [
                { slug: "activity", name: "Activity" },
                { slug: "broadcast", name: "Broadcast" },
                { slug: "disaster-map", name: "Disaster Map" },
                { slug: "inject", name: "Inject" },
                { slug: "inventory", name: "Inventory" },
                { slug: "logistics", name: "Logistics" },
                { slug: "news", name: "News" },
                { slug: "scribe", name: "Scribe" },
                { slug: "significant-event", name: "Significant Event" },
                { slug: "social-media", name: "Social Media" },
                { slug: "spreadsheet", name: "Spreadsheet" },
                { slug: "status-board", name: "Status Board" },
                { slug: "weather", name: "Weather" },
            ];

            that.simcells = await that.$http.get('/sim-cell', header).then(response => response.data);
        },
        onSubmit: async function (evt) {
            evt.preventDefault();

            const that = this;
            const data = _.clone(that.form);

            data.exercise = that.exercise._id ? that.exercise._id : undefined;

            if(data.exercise) {
                data.sendTime = that.exercise.currentTime;

                if(!data.simcell) {
                    return that.$bvToast.toast("Please enter a simcell!", {
                        title: "Error!",
                        variant: "danger",
                        solid: true,
                    });
                }
            }

            let response = null;
            if (data._id) {
                response = await that.$http.patch(`/role/${data._id}`, data);
            } else {
                response = await that.$http.post(`/role`, data);
            }

            that.$bvToast.toast("Role Saved!", {
                title: "Success",
                variant: "success",
                solid: true,
            });

            that.$emit('form', response.data);
        },
        onReset: function (evt) {
            evt.preventDefault();
            this.$emit('form', (this.item) ? this.item : {});
        },
    },
};
</script>
